/* global cloudinary */
import * as R from 'ramda'
import mime from 'mime-types'

import { createFile as createFileMutation } from './mutations.graphql'
import * as colors from 'styles/colors.module.css'

const DEFAULT_UPLOAD_PRESET = 'xrbltc4f'

const DEFAULT_UPLOAD_WIDGET_OPTIONS = {
  cloud_name: process.env.CLOUDINARY_CLOUD_NAME,
  upload_preset: DEFAULT_UPLOAD_PRESET,
  show_powered_by: false,
  multiple: false,
  sources: ['local', 'instagram', 'facebook', 'camera'],
  styles: {
    palette: {
      windowBorder: colors.NEUTRAL_GRAY_400,
      tabIcon: colors.PURPLE,
      menuIcons: colors.SHADES_BLACK,
      textDark: colors.SHADES_BLACK,
      textLight: colors.SHADES_WHITE,
      link: colors.PURPLE,
      action: colors.NEUTRAL_GRAY_300,
      inactiveTabIcon: colors.SHADES_BLACK,
      error: colors.ERROR_600,
      inProgress: colors.PURPLE,
      complete: colors.SUCCESS_500,
      sourceBg: colors.NEUTRAL_GRAY_50,
    },
    fonts: {
      [`'Heebo', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`]: {
        active: true,
      },
    },
  },
}

export const getFileFromUploadWidget = (uploadWidgetOptions = {}) => {
  return new Promise((resolve, reject) => {
    cloudinary.openUploadWidget(
      {
        ...DEFAULT_UPLOAD_WIDGET_OPTIONS,
        ...uploadWidgetOptions,
      },
      function (error, result) {
        if (error) reject(error)

        if (result.event !== 'queues-end') return
        const file = R.path(['info', 'files', 0], result)
        if (!file) return

        if (!file.type && file.uploadInfo.format) {
          file.type = mime.lookup(file.uploadInfo.format)
        }

        resolve(file)
      }
    )
  })
}

export const uploadFileFromWidget = async ({
  apolloClient,
  uploadWidgetOptions,
  eagerLoadTransformations = [],
}) => {
  const {
    name: filename,
    type: mimetype,
    uploadInfo: { public_id: cloudinaryId },
  } = await getFileFromUploadWidget(uploadWidgetOptions)

  const {
    data: { createFile: file },
  } = await apolloClient.mutate({
    mutation: createFileMutation,
    variables: {
      attachmentData: {
        cloudinaryId,
        filename,
        mimetype,
      },
      eagerLoadTransformations,
    },
  })

  return file
}
